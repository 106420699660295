import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import {dbCollections} from "@/utils/firebaseCollection"
import { getCollectionDataTypesense } from '@/utils/Typesense/queries'
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'unique_id', sortable: true },
    { key: 'student_name', sortable: true },
    { key: 'phone_no', sortable: false },
    { key: 'date_of_enrolment', sortable: true },
    { key: 'education', sortable: false,lable:'Degree' },
    { key: 'branchfiedlstream', sortable: false,label: 'Branch/Field/Stream'},
    { key: 'board_university',sortable: false },
    { key: 'intake', sortable: false },
    { key: 'year', sortable: true },
    { key: 'status', sortable: true },
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const status = ref(null)
  const yearFilter = ref(null)
  const intake = ref(null)
  const statusFilter = ref(null)
  const representativeOptions = ref(null)
  const employeeId = ref(null)
  const sortContect  = ref(null)
  const timeFnction  = ref(null)
  const students  = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, status,intake,yearFilter], () => {
    refetchData()
  })
   watch([perPage,intake,yearFilter,status],()=>{
    fetchStudentsFromTypesense(true,()=>refetchData());
  })

  onMounted(()=>{
    fetchStudentsFromTypesense(true,()=>refetchData());
    // fetchStudents()
  })
  
  var usersList = []
  var lastIntakeNo=0
  var repOptions=[{value:null,text:'All'}]
  var visited = false

  function handleSortChange(context) {
      let sortFieldObject = {
          unique_id : "createdAt",
          student_name : "name",
          phone_no:'',
          date_of_enrolment : "createdAt",
          board_university : "",
          education : "", 
          branchfiedlstream : "", 
          status : "status.status", 
          action : "", 
          year : "year", 
      }
      const {sortBy,sortDesc} = context
      sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
      fetchStudentsFromTypesense(false,()=>{refetchData()})
  }
  function handleSearch() {
    clearTimeout(timeFnction.value);
    timeFnction.value = setTimeout(() => {
      fetchStudentsFromTypesense(false,()=>{refetchData()});
    }, 1000);
  }
  function handlePageChange(newPage) {
      currentPage.value = newPage;
      fetchStudentsFromTypesense(false,()=>{refetchData()})
  }
  function fetchStudentsFromTypesense(resetResult = true,callback){
      try {
          if (resetResult) {
              currentPage.value = 1;
              totalUsers.value = 0;
          }

          let searchParameters = {
              collectionName: `${dbCollections.USERS}`,
              search: {
                  q:"*",
                  page: currentPage.value,
                  per_page: perPage.value,
              }
          }

          let filter = `isDeleted:!=true && role:='student' && representative.id:=${employeeId.value}`

          if (intake.value !== null && intake.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `intake:=${intake.value}`
          }
          if (yearFilter.value !== null && yearFilter.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `year:=${yearFilter.value}`
          }
          if (status.value !== null && status.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `status.status:=${status.value}`
          }

          if(filter !== '' && filter !== null) {
            searchParameters.search.filter_by = filter;
          }
          if (sortContect.value) {
              searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
          }
          if(searchQuery.value !== '' && searchQuery.value !== null) {
            searchParameters.search.q = searchQuery.value;
            searchParameters.search.query_by = 'name,uniqueId,status.status';
          }

          getCollectionDataTypesense(searchParameters).then(async(result)=>{
              if(result.hits.length) {
                totalUsers.value = result.found
                const arrayData = result.hits.map((x) => {
                  const studentObject = x.document;
                  var year = 0
                  var eduData = ''

                  studentObject.educationDetails.forEach(ed=>{
                    if(ed.yearOfPassing>=year)
                    {
                      eduData=ed
                    }
                  })
              
                  return {
                      id: studentObject.id,
                      status: studentObject.status,
                      unique_id: studentObject.uniqueId,
                      intake: studentObject.intake,
                      year: studentObject.year,
                      date_of_enrolment: `${new Date(studentObject.createdAt * 1000).getDate()} ${months[new Date(studentObject.createdAt * 1000).getMonth()]}, ${new Date(studentObject.createdAt * 1000).getFullYear()}`,
                      education: eduData.education || '',
                      branchfiedlstream: eduData.branchOrStreamOrFieldName || '-',
                      college_name: eduData.schoolOrCollegeName || '',
                      isDeleted: studentObject.isDeleted,
                      student_name: studentObject.name,
                      phone_no: `${studentObject.countryPhoneCode ? studentObject.countryPhoneCode.dialCode : '+91'} ` + ((studentObject.countryPhoneCode ? formatPhoneNumber(studentObject.mobileNumber,studentObject.countryPhoneCode.mask) : formatPhoneNumber(studentObject.mobileNumber,'99999 99999'))),
                      createdInSeconds: studentObject.createdAt,
                      board_university:eduData.boardUniversityName,
                  };
                });
                
                students.value = arrayData;
                callback(arrayData);

              } else {
                students.value = []
                totalUsers.value = 0;
                callback([]);
              }
          })
      } catch (error) {
          console.error(error)
          callback([]);
      }
  }
  
  const fetchUsers = (ctx, callback) => {
    var users = []
    // console.log("get List")
    if(usersList.length==0 && !visited)
    {
      visited = true
      db
        .collection(dbCollections.USERS)
        .orderBy('createdAt')
        .get()
        .then(querySnapshot=>{
          if(!querySnapshot.empty)
          {
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            // console.log(startIndex,endIndex)
            querySnapshot.forEach(doc=>{

              //Turn isDeleted Flag = false
              // db
              //   .collection(dbCollections.USERS)
              //   .doc(doc.id)
              //   .update({
              //     isDeleted:false
              //   })
              //   .catch(error => console.log(error))
              if(!doc.data().isDeleted && doc.data().role == 'student' && employeeId.value == doc.data().representative.id)
              {
                usersList.push(doc.data())

                var year = 0
                var eduData = ''

                doc.data().educationDetails.forEach(ed=>{
                  if(ed.yearOfPassing>=year)
                  {
                    eduData=ed
                  }
                })

                usersList[usersList.length-1].unique_id=doc.data().uniqueId
                usersList[usersList.length-1].date_of_enrolment=doc.data().createdAt
                
                usersList[usersList.length-1].education=eduData.education
                usersList[usersList.length-1].board_university=eduData.boardUniversityName
                usersList[usersList.length-1].student_name=doc.data().name
                usersList[usersList.length-1].phone_no=doc.data().mobileNumber
              }
              if(!doc.data().isDeleted && doc.data().role == 'employee')
              {
                repOptions.push({value:{name:doc.data().name,employeeId:doc.data().employeeId,id:doc.data().id},text:doc.data().name})
              }
              if(dataIndex>=startIndex && dataIndex<=endIndex && !doc.data().isDeleted && doc.data().role == 'student' && employeeId.value == doc.data().representative.id)
              {
                var year = 0
                var eduData = ''

                doc.data().educationDetails.forEach(ed=>{
                  if(ed.yearOfPassing>=year)
                  {
                    eduData=ed
                  }
                })
                users.push({
                  'id':doc.data().id,
                  'intake':doc.data().intake,
                  'year':doc.data().year,

                  'status':doc.data().status,
                  'unique_id':doc.data().uniqueId,
                  'date_of_enrolment':`${new Date(doc.data().createdAt.seconds*1000).getDate()} ${months[new Date(doc.data().createdAt.seconds*1000).getMonth()]}, ${new Date(doc.data().createdAt.seconds*1000).getFullYear()}`,
                  
                  'education':eduData.education,
                  'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
                  'board_university':eduData.boardUniversityName,
                  'isDeleted':doc.data().isDeleted||false,
                  'student_name':doc.data().name,
                  'phone_no':doc.data().mobileNumber,
                })
                dataIndex++;
              }
              if(index==querySnapshot.size)
              {
                totalUsers.value = usersList.length
                representativeOptions.value=repOptions
                callback(users)
              }
              index++;
            })
          }
          else
          {
            totalUsers.value = querySnapshot.size
            callback(users)
          }
        })
        .catch(error => console.log(error))
    }
    else
    {
      // console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      // console.log(startIndex,endIndex,searchQuery.value)
      if(usersList.length==0)
      {
        totalUsers.value = usersList.length
        callback(users)
        return;
      }

      if(isSortDirDesc.value==false)
      {
        // console.log("Asc")
        if(sortBy.value=='status')
        {
          usersList.sort((a, b) => (a.status.status > b.status.status ? 1 : -1))//Asc
        }
        else
        {
          usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
        }
      }
      else if(isSortDirDesc.value==true)
      {
        // console.log("Desc")
        if(sortBy.value=='status')
        {
          usersList.sort((a, b) => (a.status.status > b.status.status ? -1 : 1))//Desc
        }
        else
        {
          usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
        }
      }

      usersList.forEach(data=>{
        if(searchQuery.value=='' && status.value==null && intake.value==null && yearFilter.value==null)
        {
          if(index>=startIndex && index<=endIndex && !data.isDeleted && data.role == 'student')
          {
            var year = 0
            var eduData = ''

            data.educationDetails.forEach(ed=>{
              if(ed.yearOfPassing>=year)
              {
                eduData=ed
              }
            })
            users.push({
              'id':data.id,
              'status':data.status,
              'intake':data.intake,
              'year':data.year,
              'unique_id':data.uniqueId,
              'date_of_enrolment':`${new Date(data.createdAt.seconds*1000).getDate()} ${months[new Date(data.createdAt.seconds*1000).getMonth()]}, ${new Date(data.createdAt.seconds*1000).getFullYear()}`,
              'education':eduData.education,
              'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
              'board_university':eduData.boardUniversityName,
              'isDeleted':data.isDeleted||false,
              'student_name':data.name,
              'phone_no':data.mobileNumber,
            })
            // index++
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            callback(users)
          }
          index++;
        }
        else if(!data.isDeleted && data.role == 'student')
        {
          var year = 0
          var eduData = ''

          data.educationDetails.forEach(ed=>{
            if(ed.yearOfPassing>=year)
            {
              eduData=ed
            }
          })
          var inqDate = `${new Date(data.createdAt.seconds*1000).getDate()} ${months[new Date(data.createdAt.seconds*1000).getMonth()]}, ${new Date(data.createdAt.seconds*1000).getFullYear()}`
          users.push({
            'id':data.id,
            'intake':data.intake,
            'year':data.year,

            'status':data.status,
            'unique_id':data.uniqueId,
            'date_of_enrolment':`${new Date(data.createdAt.seconds*1000).getDate()} ${months[new Date(data.createdAt.seconds*1000).getMonth()]}, ${new Date(data.createdAt.seconds*1000).getFullYear()}`,
            
            'education':eduData.education,
            'board_university':eduData.boardUniversityName,
            'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
            'isDeleted':data.isDeleted||false,
            'student_name':data.name,
            'phone_no':data.mobileNumber,
          })
          if(index==usersList.length)
          {
            var finalArr = users;
            var tmp = [];

            var dataIndex = 1
            
            if(searchQuery.value!='')
            {
              finalArr.forEach(UD=>{
                if(
                  searchQuery.value && UD.student_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                  searchQuery.value && UD.unique_id.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                  searchQuery.value && UD.status.status.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                  searchQuery.value && UD.board_university.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                  searchQuery.value && UD.education.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                  searchQuery.value && UD.phone_no.toString().includes(searchQuery.value) ||
                  searchQuery.value && UD.date_of_enrolment.includes(searchQuery.value)
                )
                {
                  tmp.push(UD)
                }
              })
              finalArr=tmp
              tmp=[]
            }
            if(status.value!=null)
            {
              finalArr.forEach(UD=>{
                if(UD.status.status == status.value)
                {
                  tmp.push(UD)
                }
              })
              finalArr=tmp
              tmp=[]
            }
            //Intake Filter
            if(intake.value!=null)
            {
              finalArr.forEach(UD=>{
                if(UD.intake == intake.value)
                { 
                  tmp.push(UD)
                }
              })
              finalArr=tmp
              tmp=[]
            }
            //Year Filter
            if(yearFilter.value!=null)
            {
              finalArr.forEach(UD=>{
                // console.log("UD",UD.year , yearFilter.value)
                if(UD.year == yearFilter.value)
                { 
                  tmp.push(UD)
                }
              })
              finalArr=tmp
              tmp=[]
            }

            finalArr.forEach(FD=>{
              if(dataIndex>=startIndex && dataIndex<=endIndex && !FD.isDeleted)
              {
                tmp.push(FD)
              }
              dataIndex++
            })
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = finalArr.length
            callback(tmp)
          }
          index++;
        }
      })
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }

  // const closeInquiry = (ctx, callback)=>{
  //   var index = (currentPage.value-1) * perPage.value + ctx.index + 1
  //     console.log("closeInquiry",index,ctx)
  //     db
  //       .collection(dbCollections.USERS)
  //       .doc(ctx.item.id)
  //       .update({
  //         isDeleted:true
  //       })
  //       .then(()=>{
  //         usersList.splice(index-1,1)
  //         console.log(usersList)
  //         refetchData()
  //       })
  //       .catch(error=>{
  //         console.log(error)
  //       })
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    representativeOptions,
    employeeId,
    // closeInquiry,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    status,
    statusFilter,
    yearFilter,
    intake,
    handlePageChange,
    handleSortChange,
    handleSearch,
    students
  }
}
